module.exports = {
  siteTitle: 'Hello! I\'m Philip!',
  siteDescription: ` Software Developer | Virginia Beach, Virginia | Experienced developer open to full-time opportunities!`,
  keyWords: ['Philip', 'Hamilton', 'pjhamilton4', 'Philip Hamilton', 'Philip J. Hamilton', 'Developer'],
  authorName: 'Philip Hamilton',
  twitterUsername: 'pjhamilton4',
  githubUsername: 'pjhamilton4',
  authorAvatar: '/images/avatar.jpg',
  authorDescription: `<strong>Software Developer // Tinkerer </strong> <br /> <br /> 

    I have been a professional developer since 2011 and have been building computers and coding since early 2007. <br /><br />

    After graduating college in 2011 I started working for Macy's Inc. as a Software Developer
    writing enterprise warehousing management software in the Executive Development Program. <br />

    Over 8 years I worked my way up to be a Lead Software Developer for Warehouse Management Systems at Macy's Inc. <br /><br />
    
    In 2019, I decided to move back to my home state of Virginia! <br /><br />
    I currently work as a freelance developer and mostly work with Full Stack Development in Java and JavaScript. I'm always open to new opportunities!`,
  skills: [
    {
      name: 'Java',
      level: 100
    },
    {
      name: 'Java EE',
      level: 100
    },
    {
      name: 'Groovy',
      level: 100
    },
    {
      name: 'Spring (Spring Boot)',
      level: 100
    },
    {
      name: 'Javascript',
      level: 100
    },
    {
      name: 'NodeJs',
      level: 100
    },
    {
      name: 'React',
      level: 100
    },
    {
      name: 'Angular',
      level: 100
    },
    {
      name: 'SQL',
      level: 100
    },
    {
      name: 'Python',
      level: 100
    },
    {
      name: 'Linux',
      level: 100
    },
    {
      name: 'Git',
      level: 100
    }
  ],
  jobs: [
    {
      begin: {
        month: 'jan',
        year: '2020'
      },
      duration: 'current',
      occupation: "Freelance Software Developer",
      description: "Assist clients in the development and maintenance, corrective and preventive, of various software systems.",
      technologies: "Java, Spring/Spring Boot, Groovy/Grails, JavaScript, MySQL, Git, Angular"
    },
    {
      company: "Macy's Inc.",
      begin: {
        month: 'dec',
        year: '2017'
      },
      duration: '2 years',
      occupation: "Lead Software Engineer",
      description: `Lead development work on new and existing warehouse management systems development.
                    Mentor and lead team of developers around the world. Create and drive technical roadmaps, agile activities and provide production support.`,
      technologies: "Java/JavaEE, Groovy/Grails, Apache Storm, JavaScript (React), Python, Oracle, Tibco ActiveSpaces, JBoss, Spring Boot, Gurobi"
    },
    {
      company: "Macy's Inc.",
      begin: {
        month: 'mar',
        year: '2016'
      },
      duration: '1 year 10 months',
      occupation: "Senior Developer",
      description: "Full stack development for Macy's internal Warehouse Management System (WMS). Design, development and maintenance of Warehouse Management Systems deveoped in house using RESTful web services, event driven architecture",
      technologies: "Java/JavaEE, Groovy/Grails, Apache Storm, JavaScript (React), Python, Oracle, Tibco ActiveSpaces, JBoss, Gurobi"
    },
    {
      company: "Macy's Inc.",
      begin: {
        month: 'sep',
        year: '2013'
      },
      duration: '2 years 7 months',
      occupation: "Developer II",
      description: "Development of internal real-time Warehouse Management Systems, utilitizing stream processing and optimization techniques. Involved in special initiatives to bring warehouse software to store locations.",
      technologies: "Java/JavaEE, Groovy/Grails, Apache Storm, JavaScript(jQuery), Spring, Struts, Jasper and Hibernate"
    }, {
      company: "Macy's Inc.",
      begin: {
        month: 'aug',
        year: '2012'
      },
      duration: '15 months',
      occupation: "Analyst I/Developer I",
      description: "Development of Warehouse Management Systems from dashboards to report KPIs to warehouse automation tools.",
      technologies: "Java/JavaEE, Groovy/Grails, JavaScript(jQuery), Spring, Struts, Jasper and Hibernate"
    }, {
      company: "Macy's Inc.",
      begin: {
        month: 'aug',
        year: '2011'
      },
      duration: '1 year',
      occupation: "Programmer/Trainee - Executive Development Program (EDP)",
      description: "Responsible for the implementation of small features for Macy's internal warehousing solutions.",
      technologies: "Java/JavaEE, Spring, Struts, Jasper and Hibernate"

    },
  ],
  portifolio: [
    /* more portifolio items here */
  ],
  social: {
    twitter: "https://twitter.com/pjhamilton4",
    linkedin: "https://www.linkedin.com/in/philipjhamilton/",
    github: "https://github.com/pjhamilton4",
    email: "pjhamilton4@gmail.com"
  },
  siteUrl: 'https://www.philipjhamilton.com/',
  pathPrefix: '', // Note: it must *not* have a trailing slash.
  siteCover: '/images/cover.jpg',
  googleAnalyticsId: ' UA-39163674-2',
  background_color: '#ffffff',
  theme_color: '#25303B',
  fontColor: "#000000cc",
  enableDarkmode: true, // If true, enables dark mode switch
  display: 'minimal-ui',
  icon: 'src/assets/favicon-32x32.png',
  headerLinks: [
    {
      label: 'Home',
      url: '/',
    }
  ]
}